// Color Variables
$primary-color: #9d2449;
$secondary-color: #303032;
$text-color: #666;
$text-color-dark: #222;
$border-color: #e5e5e5;
$body-color: #fff;
$bg-gray: #f5f5f5;
$black: #000;
$white: #fff;

// Font Variables
$primary-font: 'Poppins', sans-serif;
$secondary-font: 'Open Sans', sans-serif;
$work-sans: 'Work Sans', sans-serif;
$icon-font: 'themify';